import { render, staticRenderFns } from "./TopMenuNew.vue?vue&type=template&id=64f6f80b&scoped=true"
import script from "./TopMenuNew.vue?vue&type=script&lang=js"
export * from "./TopMenuNew.vue?vue&type=script&lang=js"
import style0 from "./TopMenuNew.vue?vue&type=style&index=0&id=64f6f80b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f6f80b",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImgLazy, BButton, BCollapse, BModal} from 'bootstrap-vue'
    installComponents(component, {BImgLazy, BButton, BCollapse, BModal})
    


    import installDirectives from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBToggle} from 'bootstrap-vue'
    installDirectives(component, {'b-toggle': VBToggle})
    

export default component.exports